import React, { useState, useEffect } from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'
import { Button, Tooltip } from '@mui/material'
import axios from '../api/axios'
import tableStyles from './PartnersTable.module.css'
import Modal from './Modal'
import PartnerForm from './PartnerForm'
import partnerLogo from '../assets/partner_logo.svg'

const columns = (handleEditClick) => [
  {
    field: 'partnerLogoUrl',
    headerName: 'Логотип',
    flex: 0,
    width: 80,
    renderCell: (params) => (
      <img
        src={params.value || partnerLogo}
        alt="Partner Logo"
        style={{ width: 50, height: 50, objectFit: 'cover', padding: '5px', borderRadius: '50%' }}
      />
    )
  },
  { field: 'partnerName', headerName: 'Имя партнёра', flex: 1 },
  { field: 'legalName', headerName: 'Наименование юр.лица партнёра', flex: 1 },
  { field: 'phone', headerName: 'Телефон', flex: 1 },
  { field: 'contactPerson', headerName: 'Контактное лицо', flex: 1 },
  {
    field: 'link',
    headerName: 'Ссылка',
    flex: 1,
    renderCell: (params) => (
      <Tooltip title={params.value} arrow>
        <a
          href={params.value}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            display: 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: '#1a73e8',
            textDecoration: 'underline',
            cursor: 'pointer',
            maxWidth: '200px'
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {params.value}
        </a>
      </Tooltip>
    )
  },
  {
    field: 'actions',
    headerName: '',
    sortable: false,
    renderCell: (params) => (
      <button className={tableStyles.editBtn} onClick={() => handleEditClick(params.row)}>
        Изменить
      </button>
    )
  }
]

const CustomToolbar = ({ onAddClick }) => (
  <GridToolbarContainer>
    <GridToolbarQuickFilter placeholder="Поиск..." />
    <Button variant="contained" color="success" style={{ marginLeft: 'auto' }} onClick={onAddClick}>
      Добавить
    </Button>
  </GridToolbarContainer>
)

const PartnersTable = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [partners, setPartners] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [editingPartner, setEditingPartner] = useState(null)

  const transformPartnerData = (partner) => ({
    id: partner.uuid,
    partnerName: partner.display_name,
    legalName: partner.official_name,
    phone: partner.contact_phone,
    contactPerson: partner.contact_person,
    link: partner.link,
    partnerLogoUrl: partner.logo?.url || partnerLogo,
    partnerLogoUuid: partner.logo?.logo_uuid
  })

  const fetchPartners = async () => {
    setLoading(true)
    try {
      const response = await axios.get('/api/admin/v1/partners')
      const partnersData = response.data.partners.map(transformPartnerData)
      setPartners(partnersData)
    } catch (error) {
      setError(error.response?.data?.message || 'Произошла ошибка')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchPartners()
  }, [])

  const handleAddClick = () => {
    setEditingPartner(null)
    setIsModalOpen(true)
  }

  const handleEditClick = (partner) => {
    setEditingPartner(partner)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handlePartnerCreated = (newPartner) => {
    const partner = transformPartnerData(newPartner)
    setPartners((prevPartners) => [partner, ...prevPartners])
  }

  const handlePartnerUpdated = (updatedPartner) => {
    const updatedPartners = partners.map((partner) =>
      partner.id === updatedPartner.uuid ? transformPartnerData(updatedPartner) : partner
    )
    setPartners(updatedPartners)
    // console.log('Обновлен партнер:', updatedPartner)
  }

  return (
    <div style={{ width: '100%', margin: '2rem auto' }}>
      {error && <div className={tableStyles.error}>{error}</div>}
      <DataGrid
        rows={partners}
        columns={columns(handleEditClick)}
        pageSize={5}
        loading={loading}
        slots={{
          toolbar: () => <CustomToolbar onAddClick={handleAddClick} />
        }}
        sx={{
          '& .MuiDataGrid': {
            border: 'none'
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none'
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none'
          },
          '& .MuiDataGrid-footerContainer': {
            '& p': {
              padding: '0 !important'
            }
          }
        }}
      />
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <PartnerForm
          onClose={handleCloseModal}
          onPartnerCreated={handlePartnerCreated}
          onPartnerUpdated={handlePartnerUpdated}
          initialData={editingPartner}
          partnerUuid={editingPartner?.id}
          fetchPartners={fetchPartners}
        />
      </Modal>
    </div>
  )
}

export default PartnersTable
