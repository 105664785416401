import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react'
import axios from '../../api/axios'
import ConfirmationModal from '../ConfirmModal'
import ImageCropper from '../ImageCropper/ImageCropper'
import styles from './LogoUploader.module.css'
import partnerLogo from '../../assets/partner_logo.svg'
import plusIcon from '../../assets/plus_gray.svg'
import deleteIcon from '../../assets/logo_delete.svg'

const LogoUploader = forwardRef(
  ({ onFileSelect, partnerUuid, logoUrl, logoUuid, setLogoUuid, onLogoUpdate, fetchPartners }, ref) => {
    const [preview, setPreview] = useState(logoUrl || partnerLogo)
    const [selectedFile, setSelectedFile] = useState(null)
    const [cropping, setCropping] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
    const fileInputRef = useRef(null)
    const [notification, setNotification] = useState('')

    useEffect(() => {
      setPreview(logoUrl || partnerLogo)
    }, [logoUrl])

    const handleFileChange = (event) => {
      setNotification('')
      const file = event.target.files[0]
      if (!file) return

      const validImageTypes = ['image/png', 'image/jpeg']
      if (!validImageTypes.includes(file.type)) {
        alert('Только файлы PNG и JPEG поддерживаются')
        return
      }

      const maxSizeInMB = 5
      if (file.size > maxSizeInMB * 1024 * 1024) {
        alert(`Файл превышает максимальный размер в ${maxSizeInMB}MB`)
        return
      }

      const reader = new FileReader()
      reader.onload = () => {
        setPreview(reader.result)
        setCropping(true)
      }
      reader.readAsDataURL(file)
    }

    const resetSelectedFile = () => {
      setPreview(logoUrl || partnerLogo)
      setSelectedFile(null)
      onFileSelect(null)
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    }

    const confirmDeleteLogo = () => {
      setIsConfirmModalOpen(true)
    }

    const handleDeleteLogo = async () => {
      setNotification('')
      setLoading(true)
      setIsConfirmModalOpen(false)
      try {
        await axios.delete(`/api/admin/v1/partner/logo/${partnerUuid}`)
        setPreview(partnerLogo)
        setLogoUuid(null)
        onFileSelect(null)
        onLogoUpdate(null, null)
        fetchPartners()
        setNotification('Логотип удален')
      } catch (error) {
        alert('Ошибка удаления логотипа.', error)
      } finally {
        setLoading(false)
      }
    }

    const handleCropComplete = (croppedFile) => {
      setCropping(false)
      setPreview(URL.createObjectURL(croppedFile))
      setSelectedFile(croppedFile)
      onFileSelect(croppedFile)
    }

    useImperativeHandle(ref, () => ({
      uploadLogo: async (uuid) => {
        if (!selectedFile) return

        const formData = new FormData()
        formData.append('file', selectedFile)
        setNotification('')
        setLoading(true)
        try {
          const requestType = logoUuid ? 'put' : 'post'
          const url = logoUuid
            ? `/api/admin/v1/partner/logo/${uuid}`
            : `/api/admin/v1/partner/logo?partner_uuid=${uuid}`

          const response = await axios[requestType](url, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })

          if (response.data) {
            const newLogoUuid = response.data.logo_uuid
            const newLogoUrl = response.data.url
            setLogoUuid(newLogoUuid)
            setPreview(newLogoUrl)
            onLogoUpdate(newLogoUrl, newLogoUuid)
          }
        } catch (error) {
          alert('Ошибка загрузки логотипа. ', error)
        } finally {
          setLoading(false)
        }
      }
    }))

    if (cropping) {
      return (
        <ImageCropper
          image={preview}
          onCancel={() => {
            resetSelectedFile()
            setCropping(false)
          }}
          onCropComplete={handleCropComplete}
        />
      )
    }

    return (
      <>
        <div className={styles.logoWrapper}>
          <img src={preview} alt='Logo Preview' className={styles.logoImg} />

          <div
            className={styles.addIconWrapper}
            onClick={() => fileInputRef.current.click()}
          >
            <img src={plusIcon} alt='Добавить логотип' className={styles.addIcon} />
          </div>

          {!loading && (
            selectedFile
              ? (
                <div className={styles.deleteIconWrapper} onClick={resetSelectedFile}>
                  <img src={deleteIcon} alt='Отменить выбор файла' className={styles.deleteIcon} />
                </div>)
              : logoUuid && (
                <div className={styles.deleteIconWrapper} onClick={confirmDeleteLogo}>
                  <img src={deleteIcon} alt='Удалить логотип' className={styles.deleteIcon} />
                </div>
              )
          )}

          <input
            type='file'
            accept='image/png, image/jpeg'
            onChange={handleFileChange}
            ref={fileInputRef}
            style={{ display: 'none' }}
          />

          <ConfirmationModal
            isOpen={isConfirmModalOpen}
            onClose={() => setIsConfirmModalOpen(false)}
            onConfirm={handleDeleteLogo}
          >
            Вы уверены, что хотите удалить логотип?
          </ConfirmationModal>
        </div>
        {notification && <div className={styles.notificationMessage}>{notification}</div>}
      </>
    )
  }
)

LogoUploader.displayName = 'LogoUploader'

export default LogoUploader
