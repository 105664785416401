import React, { useState, useRef } from 'react'
import axios from 'axios'
import apiAxios from '../api/axios'
import './VideoUpload.css'

const VideoUpload = () => {
  const fileInput = useRef(null)
  const abortController = useRef(null)

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [uploadProgress, setUploadProgress] = useState(0)
  const [notification, setNotification] = useState('')
  const [cancelMessage, setCancelMessage] = useState('')
  const [uploadResponse, setUploadResponse] = useState(null)
  const [fileName, setFileName] = useState('Выберите файл')
  const [isUploading, setIsUploading] = useState(false)

  const handleFileSelect = (event) => {
    const file = event.target.files[0]
    if (file && file.type.startsWith('video/')) {
      setFileName(file.name)
      setUploadResponse(null)
      setNotification('')
      setCancelMessage('')
    } else {
      setFileName('Выберите файл')
      showError('Пожалуйста, выберите видеофайл.')
      fileInput.current.value = ''
    }
  }

  const showError = (message) => {
    window.alert('Error: ' + message)
  }

  const uploadVideo = async () => {
    setUploadProgress(0)
    setNotification('')
    setCancelMessage('')

    if (title.length < 4) {
      showError('Название должно содержать не менее 4 символов.')
      return
    }

    const file = fileInput.current.files[0]

    if (!file) {
      showError('Видео не выбрано')
      return
    }

    if (file.size > 40 * 1024 * 1024) {
      showError('Пожалуйста, выберите видеофайл размером менее 40 МБ.')
      return
    }

    const formData = new FormData()
    formData.append('small_240', file)
    formData.append('title', title)
    formData.append('description', description)

    try {
      setIsUploading(true)
      abortController.current = new AbortController()
      const { signal } = abortController.current

      const response = await apiAxios.post(
        '/api/admin/v1/content/facts/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            setUploadProgress(percentCompleted)
          },
          signal
        }
      )

      if (response.data) {
        setUploadResponse(response.data)
        setNotification('Видео успешно загружено!')
      } else {
        showError('Не удалось загрузить.' + response.data.status)
      }
    } catch (error) {
      // console.log(error)
      if (axios.isCancel(error) || error.code === 'ERR_CANCELED') {
        setCancelMessage('Загрузка отменена.')
      } else {
        showError('Не удалось загрузить.' + error.message)
      }
    } finally {
      setIsUploading(false)
      setUploadProgress(0)
      setTitle('')
      setDescription('')
      setFileName('Выберите файл')
      fileInput.current.value = ''
    }
  }

  const cancelUpload = () => {
    if (abortController.current) {
      abortController.current.abort()
      setIsUploading(false)
    }
  }

  return (
    <div className="video-upload-wrapper">
      <div className="video-upload-container">
        <div className="form-group">
          <label className="form-label" htmlFor="title">Заголовок:*</label>
          <input
            className="upload-input"
            maxLength={60}
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="description">Описание:</label>
          <textarea
            className="upload-input description"
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="file-upload-group">
          <label className="file-upload-button" htmlFor="file-input">{fileName}</label>
          <input
            className="file-input"
            type="file"
            id="file-input"
            ref={fileInput}
            accept="video/*"
            style={{ display: 'none' }}
            onChange={handleFileSelect}
          />
          <button
            className={`upload-button ${isUploading ? 'cancel-video-button' : ''}`}
            onClick={isUploading ? cancelUpload : uploadVideo}
          >
            {isUploading ? 'Отменить' : 'Загрузить'}
          </button>
        </div>
        {uploadProgress > 0 && (
          <progress className="upload-progress" value={uploadProgress} max="100" />
        )}
        {notification && <div className="upload-notification">{notification}</div>}
        {cancelMessage && <div className="cancel-notification">{cancelMessage}</div>}
        {uploadResponse && (
          <div>
            <p>Дата создания: {new Date(uploadResponse.created_at).toLocaleDateString('en-GB')}</p>
            <p>Автор: {uploadResponse.author.email} {uploadResponse.author.role}</p>
            <p>Заголовок: {uploadResponse.title}</p>
            {uploadResponse.description && <p>Описание: {uploadResponse.description}</p>}
            {uploadResponse.video.map((video, index) => (
              <div key={index}>
                <p>Имя файла: {video.file_name}</p>
                <p>Размер файла: {(video.size / 1048576).toFixed(1)} MB</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default VideoUpload
