import React, { useState, useEffect } from 'react'
import Cropper from 'react-easy-crop'
import styles from './ImageCropper.module.css'

const MIN_WIDTH = 200
const MIN_HEIGHT = 200

const ImageCropper = ({ image, onCancel, onCropComplete }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  useEffect(() => {
    if (!image) return

    // Определяем MIME-тип
    // const mimeType = image.startsWith('data:image/jpeg')
    //   ? 'image/jpeg'
    //   : image.startsWith('data:image/png')
    //     ? 'image/png'
    //     : null

    // console.log('Тип исходного изображения:', mimeType || 'Неопределён')

    const img = new Image()
    img.crossOrigin = 'anonymous' // Поддержка прозрачности
    img.src = image

    img.onload = () => {
      // console.log('Исходное изображение:', {
      //   width: img.width,
      //   height: img.height,
      //   mimeType: mimeType || 'Неопределён'
      // })

      if (img.width < MIN_WIDTH || img.height < MIN_HEIGHT) {
        alert(
          `Изображение слишком маленькое. Минимальные размеры: ${MIN_WIDTH}x${MIN_HEIGHT}px.`
        )
        onCancel()
      }
    }

    img.onerror = () => {
      // console.error('Ошибка загрузки изображения. Возможно, файл поврежден.')
      alert('Ошибка загрузки изображения. Пожалуйста, попробуйте другой файл.')
      onCancel()
    }
  }, [image, onCancel])

  const handleCropComplete = (_, croppedAreaPixels) => {
    // console.log('Обрезанная область (в пикселях):', croppedAreaPixels)
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const cropImage = async () => {
    try {
      const canvas = document.createElement('canvas')
      const img = new Image()
      img.crossOrigin = 'anonymous' // Поддержка прозрачности
      img.src = image

      await new Promise((resolve) => {
        img.onload = resolve
      })

      // console.log('Начинаем обрезку изображения...', {
      //   originalWidth: img.naturalWidth,
      //   originalHeight: img.naturalHeight,
      //   croppedAreaPixels
      // })

      // Рассчитываем масштаб
      const scaleX = img.naturalWidth / img.width
      const scaleY = img.naturalHeight / img.height

      const { width, height, x, y } = croppedAreaPixels

      // Устанавливаем размеры canvas с учетом масштаба
      canvas.width = Math.floor(width * scaleX)
      canvas.height = Math.floor(height * scaleY)

      const ctx = canvas.getContext('2d', { alpha: true })
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      // Рисуем обрезанную область изображения
      ctx.drawImage(
        img,
        x * scaleX,
        y * scaleY,
        width * scaleX,
        height * scaleY,
        0,
        0,
        canvas.width,
        canvas.height
      )

      // console.log('Размеры обрезанного изображения:', {
      //   width: canvas.width,
      //   height: canvas.height
      // })

      // Проверяем MIME-тип
      const mimeType = image.startsWith('data:image/jpeg')
        ? 'image/jpeg'
        : image.startsWith('data:image/png')
          ? 'image/png'
          : 'image/png' // По умолчанию PNG

      const fileExtension = mimeType === 'image/jpeg' ? 'jpg' : 'png'

      // console.log('Формат результата:', {
      //   mimeType,
      //   fileExtension
      // })

      canvas.toBlob(
        (blob) => {
          const croppedFile = new File([blob], `cropped_image.${fileExtension}`, {
            type: mimeType
          })
          // console.log('Файл обрезанного изображения:', croppedFile)
          onCropComplete(croppedFile)
        },
        mimeType,
        mimeType === 'image/jpeg' ? 0.9 : 1 // Качество для JPEG
      )
    } catch (error) {
      alert('Ошибка обрезки изображения:', error)
    }
  }

  return (
    <div className={styles.cropWrapper}>
      <div className={styles.cropContainer}>
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          aspect={1}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={handleCropComplete}
          cropShape="round"
          showGrid={false}
        />
      </div>
      <div className={styles.controls}>
        <input
          type="range"
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e) => setZoom(e.target.value)}
          className={styles.zoomRange}
        />
        <div className={styles.actionBtns}>
          <button onClick={onCancel} className={styles.cancelButton} type='button'>
            Отмена
          </button>
          <button onClick={cropImage} className={styles.cropButton} type='button'>
            Обрезать
          </button>
        </div>
      </div>
    </div>
  )
}

export default ImageCropper
