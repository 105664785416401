/*eslint-disable*/
import React, {useEffect, useState} from "react";
import axios from "../api/axios";
import Modal from "./Modal";
import styles from "./VideoList.module.css";
import Pagination from "@mui/material/Pagination";
import {useLocation, useNavigate} from "react-router-dom";
import VideoEdit from "../components/videoEdit";
import ModalHashtag from "../components/ModalHashtag";
import * as PropTypes from "prop-types";
import {GroupActions} from "../components/entertain/list/GroupActions";

GroupActions.propTypes = {
    video: PropTypes.any,
    onClick: PropTypes.func,
    onClick1: PropTypes.func
};

const VideoList = () => {
    const [videos, setVideos] = useState([]);
    const [error, setError] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalHashtagOpen, setIsModalHashtagOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [totalCount, setTotalCount] = useState(0);
    const limit = 10;
    const [searchQuery, setSearchQuery] = useState("");
    const [lastSearchQuery, setLastSearchQuery] = useState("");

    const query = new URLSearchParams(location.search);
    const pageFromUrl = parseInt(query.get("page") || "1", 10);
    const zeroIndexedPage = pageFromUrl - 1;

    const [debounceTimeout, setDebounceTimeout] = useState(null)
    const [inputValue, setInputValue] = useState('')


    const fetchVideos = async () => {
        try {
            const offset = zeroIndexedPage * limit;
            let apiUrl = `api/admin/v1/content/facts?offset=${offset}&limit=${limit}`;
            if (searchQuery) {
                apiUrl += `&query=${encodeURIComponent(searchQuery)}`;
            }
            const response= await axios.get(apiUrl);
            setVideos(response.data.videos);
            setTotalCount(response.data.count);
            setLastSearchQuery(searchQuery);
        } catch (error) {
            console.error("Error fetching videos: ", error);
            setError("Error fetching videos");
        }
    };

    useEffect(() => {
        fetchVideos();
    }, [zeroIndexedPage, searchQuery]);

    const handleEditClick = (clickEvent, video) => {
        clickEvent.stopPropagation();

        setSelectedVideo(video);
        setIsModalHashtagOpen(true);
    };

    const deleteVideo = async (clickEvent, videoId) => {
        clickEvent.stopPropagation();
        if (confirm("Удалить выбранный элемент?\nНажмите Отмена если нажали случайно") == false) {
            return
        }
        try {
            const response = await axios.delete(
                `api/admin/v1/content/facts/${videoId}`
            );
            if (response.status === 200) {
                setVideos(videos.filter((video) => video.id !== videoId));
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setError("Unauthorized: Invalid JWT");
                } else if (error.response.status === 403) {
                    setError("Forbidden: Access Denied");
                } else {
                    setError("Error deleting video");
                }
            } else {
                setError("Error deleting video");
            }
            console.error("Error deleting video: ", error);
            setTimeout(() => setError(null), 3000);
        }
    };

    const handleSearchChange = (event) => {
        const query = event.target.value
        setInputValue(query)
        if (debounceTimeout) {
          clearTimeout(debounceTimeout)
        }
        const newTimeout = setTimeout(() => {
            setSearchQuery(query)
            if (event.target.value !== lastSearchQuery && pageFromUrl !== 1) {
                navigate("?page=1");
            }
        }, 1000)
        setDebounceTimeout(newTimeout)
    };

    useEffect(() => {
        return () => {
            if (debounceTimeout) {
                clearTimeout(debounceTimeout);
            }
        };
    }, [debounceTimeout]);

    const handleQualityClick = async (videoId, quality) => {
        try {
            const response = await axios.get(
                `api/admin/v1/content/facts/${videoId}/${quality}`
            );
            setSelectedVideo(response.data);
            setIsModalOpen(true);
        } catch (error) {
            console.error("Error fetching video: ", error);
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const closeModalHashtag = () => {
        setIsModalHashtagOpen(false);
    };

    const handleChangePage = (event, value) => {
        navigate(`?page=${value}`);
    };

    const totalPages = Math.ceil(totalCount / limit);

    return (
        <div className={styles.videoListContainer}>
            <div className='container'>
                {error ? (
                    <p>{error}</p>
                ) : (
                    <div>
                        <div className={styles.paginationContainer}>
                            <Pagination
                                count={totalPages}
                                page={zeroIndexedPage + 1}
                                onChange={handleChangePage}
                                showFirstButton
                                showLastButton
                            />
                        </div>
                        <div className={styles.searchContainer}>
                            <div className={styles.searchIcon}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                >
                                    <path
                                        fill="black"
                                        d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16a6.468 6.468 0 0 0 4.23-1.392l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
                                    ></path>
                                </svg>
                            </div>
                            <input
                                type="text"
                                maxLength={49}
                                placeholder="Поиск..."
                                value={inputValue}
                                onChange={handleSearchChange}
                                className={styles.searchInput}
                            />
                        </div>
                        <ul className={styles.videoList}>
                            {videos.map((video) => (
                                <li
                                key={video.id}
                                className={styles.videoItem}
                                onClick={() => handleQualityClick(video.id, "small_240")}
                                >
                                    <img
                                        src={video.poster_url}
                                        alt={video.title}
                                        className={styles.videoThumbnail}
                                    />
                                    <div className={styles.videoListInfoWrapper}>
                                        <div className={styles.videoInfo}>
                                            <h3 className={styles.videoTitle}>{video.title}</h3>
                                            <p className={styles.videoDetails}>
                                            {new Date(video.created_at).toLocaleDateString("en-GB")}
                                            </p>
                                            <p className={styles.videoDetails}>{video.author.email}</p>
                                            <p className={styles.videoDetails}>{video.description}</p>
                                        </div>
                                        <div className={styles.buttonsWrapper}>
                                            <GroupActions
                                                video={video}
                                                onClick={(clickEvent) => handleEditClick(clickEvent, video)}
                                                onClick1={(clickEvent) => deleteVideo(clickEvent, video.id)}
                                            />
                                        </div>
                                        
                                    </div>
                                </li>
                            ))}
                            </ul>

                        <Modal
                            isOpen={isModalOpen}
                            onClose={closeModal}
                            videoUrl={selectedVideo ? selectedVideo.url : ""}
                            title={selectedVideo ? selectedVideo.title : ""}
                        />
                        <ModalHashtag isOpen={isModalHashtagOpen} onClose={closeModalHashtag}>
                            {selectedVideo && (
                                <VideoEdit videoId={selectedVideo.id} currentTitle={selectedVideo.title}
                                           currentDescription={selectedVideo.description} fetchVideos={fetchVideos}
                                           onClose={closeModalHashtag}/>
                            )}
                        </ModalHashtag>
                        <div className={styles.paginationContainer}>
                            <Pagination
                                count={totalPages}
                                page={zeroIndexedPage + 1}
                                onChange={handleChangePage}
                                showFirstButton
                                showLastButton
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default VideoList;