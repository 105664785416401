/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react'
import axios from '../../api/axios'
import { useUser } from '../../contexts/UserContext'
import styles from './AvatarUploader.module.css'
import userIcon from '../../assets/user_icon.svg'
import ConfirmationModal from '../ConfirmModal'
import ImageCropper from '../ImageCropper/ImageCropper'

const AvatarUploader = () => {
  const [selectedFile, setSelectedFile] = useState(null)
  const [preview, setPreview] = useState(null)
  const [base64Image, setBase64Image] = useState(null) // Добавлено для хранения Base64 строки
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [cropModalOpen, setCropModalOpen] = useState(false)

  const fileInputRef = useRef(null)
  const { avatar, setAvatar } = useUser()

  useEffect(() => {
    if (avatar && typeof avatar === 'object' && avatar.image_url) {
      setPreview(avatar.image_url)
    } else if (typeof avatar === 'string') {
      setPreview(avatar)
    }
  }, [avatar])

  const handleFileChange = (event) => {
    setError('')
    const file = event.target.files[0]

    if (!file) return

    const validImageTypes = ['image/png', 'image/x-png', 'image/jpeg', 'image/jpg']
    if (!validImageTypes.includes(file.type)) {
      setError('Только файлы PNG и JPEG поддерживаются')
      return
    }

    const maxSizeInMB = 5
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024
    if (file.size > maxSizeInBytes) {
      setError(`Файл превышает максимальный размер в ${maxSizeInMB}MB`)
      return
    }

    const reader = new FileReader()
    reader.onload = () => {
      setSelectedFile(file)
      setBase64Image(reader.result) // Сохраняем Base64 строку для передачи в ImageCropper
      setCropModalOpen(true)
    }
    reader.readAsDataURL(file) // Преобразуем файл в Base64
  }

  const handleCropComplete = (croppedFile) => {
    setSelectedFile(croppedFile)
    setPreview(URL.createObjectURL(croppedFile))
    setCropModalOpen(false)
  }

  const handleUpload = async () => {
    if (!selectedFile) {
      setError('Пожалуйста, выберите файл')
      return
    }

    const formData = new FormData()
    formData.append('file', selectedFile)

    try {
      setLoading(true)
      setError(null)
      const response = await axios.post('/api/admin/v1/avatar/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      if (response.status === 200) {
        const { avatar_uuid, image_url } = response.data
        await handleSetAvatar(avatar_uuid, image_url)
        setSelectedFile(null)
        setPreview(null)
      } else {
        setError('Ошибка при загрузке аватара')
      }
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }

  const handleSetAvatar = async (uuid, url) => {
    try {
      setLoading(true)
      setError(null)
      const response = await axios.put(`/api/admin/v1/avatar/set/${uuid}`)

      if (response.status === 200) {
        setAvatar({ avatar_uuid: uuid, image_url: url })
      } else {
        setError('Ошибка при установке аватара')
      }
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }

  const handleDeleteAvatar = async () => {
    if (!avatar) {
      setError('Аватар не найден для удаления')
      return
    }

    try {
      setLoading(true)
      setError(null)
      const response = await axios.delete(`/api/admin/v1/avatar/delete/${avatar.avatar_uuid}`)

      if (response.status === 200) {
        setAvatar(null)
        setSelectedFile(null)
        setPreview(null)
      } else {
        setError('Ошибка при удалении аватара')
      }
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
      setIsModalOpen(false)
    }
  }

  const handleError = (err) => {
    if (err.response) {
      switch (err.response.status) {
        case 400:
          setError('Неверный запрос. Пожалуйста, проверьте данные.')
          break
        case 401:
          setError('Ошибка авторизации. Пожалуйста, войдите снова.')
          break
        case 500:
          setError('Внутренняя ошибка сервера. Попробуйте позже.')
          break
        default:
          setError('Произошла ошибка. Пожалуйста, попробуйте еще раз.')
      }
    } else {
      setError('Ошибка сети. Проверьте подключение.')
    }
  }

  const handleAddPhotoClick = () => {
    fileInputRef.current.click()
  }

  const handleCancelSelection = () => {
    setSelectedFile(null)
    setPreview(null)
    setBase64Image(null)
    setError(null)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  const openDeleteModal = () => {
    setIsModalOpen(true)
  }

  const closeDeleteModal = () => {
    setIsModalOpen(false)
  }

  return (
    <div className={styles.avatarUploader}>
      <div className={styles.avatarWrapper}>
        {avatar
          ? (
          <img src={preview || avatar.image_url} alt="Current Avatar" className={styles.avatarImg} />
            )
          : preview
            ? (
          <>
            <img src={preview} alt="Avatar Preview" className={styles.avatarImg} />
            <button className={styles.cancelButton} onClick={handleCancelSelection} disabled={loading}>
            </button>
          </>
              )
            : (
          <img src={userIcon} alt="Default Icon" className={styles.avatarImg} />
              )}

        {!avatar && (
          <img
            src={require(`../../assets/${selectedFile ? 'image_remove.png' : 'image_add.png'}`)}
            alt={selectedFile ? 'Remove Icon' : 'Add Icon'}
            className={styles.addIcon}
            onClick={selectedFile ? handleCancelSelection : handleAddPhotoClick}
          />
        )}

        <input
          type="file"
          accept="image/png, image/jpeg"
          onChange={handleFileChange}
          ref={fileInputRef}
          style={{ display: 'none' }}
        />
      </div>

      <div className={styles.buttonWrapper}>
        {avatar && (
          <button className={styles.deleteButton} onClick={openDeleteModal} disabled={loading}>
            {loading ? 'Удаление...' : 'Удалить аватар'}
          </button>
        )}

        {!avatar && (
          <button className={styles.uploadButton} onClick={selectedFile ? handleUpload : handleAddPhotoClick} disabled={loading}>
            {loading ? 'Загрузка...' : selectedFile ? 'Установить как аватар' : 'Выбрать фото'}
          </button>
        )}
      </div>

      {error && <p className={styles.errorMessage}>{error}</p>}

      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={closeDeleteModal}
        onConfirm={handleDeleteAvatar}
      >
        <p>Вы уверены, что хотите удалить аватар?</p>
      </ConfirmationModal>

      {cropModalOpen && base64Image && (
        <ImageCropper
          image={base64Image} // Передаем Base64 строку
          onCancel={() => {
            setCropModalOpen(false)
            handleCancelSelection()
          }}
          onCropComplete={handleCropComplete}
        />
      )}
    </div>
  )
}

export default AvatarUploader
