/*eslint-disable */
import React, { useState } from "react";
import axios from "../api/axios";
import styles from "./VideoLessonUpload.module.css";
import ConfirmationModal from "./ConfirmModal";
import CopyLinkButton from "./CopyLinkButton";

const VideoLessonUpload = ({
  directionUuid,
  lessonUuid,
  lessonVideo = [],
  fetchLessonVideos,
  setIsLessonFull,
}) => {
  const [videoFile, setVideoFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const landingPageUrl = process.env.REACT_APP_L_SITE_URL || 'https://saasmedia.ru';

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("video/") && file.size < 40 * 1024 * 1024) {
      setVideoFile(file);
      setResponseMessage("");
    } else {
      setVideoFile(null);
      setResponseMessage("Пожалуйста, выберите видеофайл размером менее 40 МБ.");
      e.target.value = null
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResponseMessage("");
    if (!videoFile) {
      setResponseMessage("Пожалуйста, выберите видеофайл");
      return;
    }
    if (videoFile.size > 40 * 1024 * 1024) {
      setResponseMessage('Пожалуйста, выберите видеофайл размером менее 40 МБ.')
      return
    }

    const formData = new FormData();
    formData.append("video", videoFile);

    try {
      setUploading(true);
      const response = await axios.post(
        `/api/admin/v1/learn/lesson/${lessonUuid}/video`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        }
      );
      fetchLessonVideos();
      setResponseMessage("Видео успешно загружено.");
    } catch (error) {
      setResponseMessage(
        error.response?.data?.message || "Ошибка при загрузке видео."
      );
    } finally {
      setUploading(false);
    }
  };

  const handleDeleteClick = () => {
    setIsModalOpen(true);
  };

  const handleDeleteVideo = async () => {
    try {
      const response = await axios.delete(
        `/api/admin/v1/learn/lesson/video/${lessonVideo[0].id}`
      );
      fetchLessonVideos();
      setIsLessonFull(false);
      setResponseMessage("Видео успешно удалено.");
      setIsModalOpen(false);
    } catch (error) {
      setResponseMessage(
        error.response?.data?.message || "Ошибка при удалении видео."
      );
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={styles.container}>
      {lessonVideo && lessonVideo.length === 0 && (
        <form onSubmit={handleSubmit} className={styles.uploadForm}>
          <input
            type="file"
            onChange={handleFileChange}
            accept="video/*"
            className={styles.uploadInput}
          />
          <button
            type="submit"
            disabled={uploading}
            className={styles.uploadButton}
          >
            {uploading ? `Загрузка...${uploadProgress}%` : "Загрузить"}
          </button>
        </form>
      )}
      {responseMessage && (
        <p className={styles.responseMessage}>{responseMessage}</p>
      )}
      {uploading && (
        <progress
          value={uploadProgress}
          max="100"
          className={styles.progressBar}
        ></progress>
      )}
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleDeleteVideo}
      >
        <p>Вы уверены, что хотите удалить это видео?</p>
      </ConfirmationModal>
      {lessonVideo && lessonVideo.length > 0 && (
        <div className={styles.videoContainer}>
          <video className={styles.videoPlayer} controls>
            <source src={lessonVideo[0].url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className={styles.buttonGroup}>
            <CopyLinkButton
            link={`${landingPageUrl}/video/${lessonVideo[0]?.uuid}`}
            />
            <button
              type="button"
              onClick={handleDeleteClick}
              className={styles.deleteButton}
            >
              Удалить видео
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoLessonUpload;
