import React, { useState } from 'react'
import styles from './CopyLinkButton.module.css'

const CopyLinkButton = ({ link }) => {
  const [copyMessage, setCopyMessage] = useState('')

  const handleCopyLink = (e) => {
    e.stopPropagation()
    setCopyMessage('')
    if (link) {
      navigator.clipboard
        .writeText(link)
        .then(() => {
          setCopyMessage('Ссылка скопирована.')
        })
        .catch(() => {
          setCopyMessage('Ошибка при копировании ссылки')
        })
    }
  }

  return (
    <div className={styles.copyLinkContainer}>
      <button onClick={handleCopyLink} className={styles.copyButton}>
        Скопировать ссылку
      </button>
      {copyMessage && <p className={styles.copyMessage}>{copyMessage}</p>}
    </div>
  )
}

export default CopyLinkButton
